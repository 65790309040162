<template>
  <div>
    <Loading v-show="show" />
    <div class="background-area overlay-dark-fullwidth no-animation">
      <img loading="lazy" alt="" id="ImagesFID" style="width: 100%" />
      <div class="banner-content static-text">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="text-content-wrapper text-center full-width">
                <div class="text-content table-cell">
                  <h1
                    class="title1 cd-headline push text-center border-none mt-40"
                  >
                    <span class="cd-words-wrapper">
                      <b id="postTitleId" class="is-visible"></b>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-form-area section-padding" v-show="showAbout">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12">
            <!-- <h4 class="contact-title"></h4> -->
            <div class="contact-text">
              <p
                v-for="about in About"
                :key="about.postID"
                style="background-color: rgb(52, 152, 219) !important"
              >
                <span class="c-icon"><i class="fa fa-chevron-left"></i></span
                ><span class="c-text"
                  ><a
                    href="#"
                    aria-label="title"
                    @click="getAboutById(about.postID)"
                    >{{ about.postTitle }}</a
                  ></span
                >
              </p>
            </div>
          </div>
          <div class="col-lg-9 col-12">
            <div class="course-details-content">
              <div class="single-course-details">
                <div class="row">
                  <div class="col-md-12">
                    <div class="single-item-text">
                      <h5 id="postTitle_"></h5>
                      <p id="postSubTitle_"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="showMessage"
      class="about_area_2 about-area_2"
      v-bind:style="{
        'margin-top': '20px',
        background:
          'rgba(0, 0, 0, 0) url(https://api2.yuniv.net:444/Images/post/' +
          ImageMess +
          ')',
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="about-container">
              <h3>{{ TitleMess }}</h3>
              <p>
                {{ SubMess }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="event-area section-padding bg-white" v-show="showActivity">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3 style="margin-bottom: 24px">{{ Magazine }}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            style="margin-top: 24px"
            v-for="event in Events"
            :key="event.postId"
            class="col-lg-3 col-md-6 col-12"
          >
            <div class="single-event-item">
              <div class="single-event-image">
                <a>
                  <img
                    loading="lazy"
                    alt=""
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' +
                      event.postImage
                    "
                  />
                </a>
              </div>
              <div class="single-event-text">
                <h6>
                  <a
                    aria-label="title"
                    style="line-height: 2; text-align: justify"
                    >{{ event.postTitle }}
                  </a>
                </h6>
                <p style="text-align: justify">
                  {{ event.postSubTitle }}
                </p>
                <!-- <a v-show="event.postFile" class="button-default" target="_blank" :href="'https://api2.yuniv.net:444/images/post/'+event.postFile">{{DownloadFile}}</a>
                             <br/> -->
                <router-link
                  href="#"
                  aria-label="title"
                  class="button-default"
                  :to="{
                    name: 'IssueArtical-with-id',
                    params: {
                      type: 'Univ.Research.issue.artical',
                      id: event.postID,
                      BrnId: event.brnID,
                    },
                  }"
                >
                  {{ Detials }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 text-center">
          <router-link
            href="#"
            aria-label="title"
            :to="{
              name: 'issue-with-BrnId-And-Type',
              params: {
                type: 'Univ.Research.issue',
                BrnId: $route.params.id,
              },
            }"
            class="button-default button-large"
          >
            {{ BtnMore }} <i class="fa fa-chevron-left"></i
          ></router-link>
        </div>
      </div>
    </div>

    <!-- service start -->
    <div
      v-show="showServices"
      class="latest-area section-padding"
      style="background-color: #3498db; padding: 60px 0 67px"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper">
              <div class="section-title">
                <h3 style="margin-bottom: 24px; color: #fff">{{ Service }}</h3>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12" style="text-align: left">
            <router-link
              href="#"
              aria-label="title"
              :to="{
                name: 'Univ-with-Type',
                params: {
                  type: 'Univ.Research.Home.Services',
                },
              }"
              class="button-default button-large"
            >
              {{ BtnMore }} <i class="fa fa-chevron-left"></i
            ></router-link>
          </div>
        </div>

        <div class="row">
          <div
            v-for="servic in Services"
            :key="servic.postID"
            class="col-lg-3 col-md-6"
          >
            <div class="single-event-item">
              <div class="single-event-image">
                <a>
                  <img
                    loading="lazy"
                    alt=""
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' +
                      servic.postImage
                    "
                  />
                </a>
              </div>
              <div class="single-event-text">
                <h6 style="margin-bottom: 10px">
                  <a>{{ servic.postTitle }}</a>
                </h6>
                <!-- <p>{{ servic.postSubTitle }}</p> -->

                <router-link
                  href="#"
                  aria-label="title"
                  class="button-default"
                  :to="{
                    name: 'Service',
                    params: {
                      id: servic.postID,
                      type: servic.modID,
                    },
                  }"
                >
                  {{ Detials }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- service end -->

    <!--Videos Area Start-->
    <div
      class="course-area section-padding course-page"
      style="padding: 40px 0; margin-bottom: 0px"
      v-show="showVideos"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="section-title-wrapper section-title-wrapper1">
              <div class="section-title">
                <h3>{{ LibraryVideos }}</h3>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12" style="text-align: left">
            <router-link
              href="#"
              aria-label="title"
              style="margin-bottom: 60px"
              :to="{
                name: 'Videos',
                params: {
                  type: 'Univ.Research.Home.Videos',
                },
              }"
              class="button-default button-large"
            >
              {{ BtnMore1 }} <i class="fa fa-chevron-left"></i
            ></router-link>
          </div>
          <div
            v-for="videos in Videos"
            :key="videos.postId"
            class="col-lg-4 col-md-6 col-12"
          >
            <div class="single-item single-item1">
              <div class="single-item-image overlay-effect">
                <a
                  @click="showVideo(videos.postID)"
                  data-toggle="modal"
                  data-target=".exampleModal"
                  ><img
                    loading="lazy"
                    :src="
                      'https://api2.yuniv.net:444/images/post/' +
                      videos.postImage
                    "
                    alt=""
                  />
                  <i class="fa fa-play-circle"></i>
                </a>
              </div>
              <div class="single-item-text">
                <h4>{{ videos.postTitle }}</h4>
              </div>
              <div class="button-bottom">
                <a
                  style="cursor: pointer"
                  @click="showVideo(videos.postID)"
                  data-toggle="modal"
                  data-target=".exampleModal"
                  class="button-default"
                  >{{ watch }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Videos Area-->
    <!-- Modal start -->
    <div
      class="modal fade exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <iframe
              width="100%"
              style="height: 500px"
              class="mfp-iframe"
              id="postURL"
              frameborder="0"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
  </div>
</template>
<script>
import Loading from "./Loading";
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      showAbout: true,
      showMessage: true,
      showActivity: true,
      showServices: true,
      showVideos: true,
      About: [],
      Events: [],
      Services: [],
      Videos: [],
      ImageMess: "",
      TitleMess: "",
      SubMess: "",

      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      Magazine: "",
      BtnMore: "",
      Detials: "",
      DownloadFile: "",
      Service: "",
      LibraryVideos: "",
      BtnMore1: "",
      watch: "",
    };
  },
  methods: {
    getAboutById(id) {
      var bodyFormData1 = new FormData();
      bodyFormData1.append("check", "getPostById");
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("PostId", id);
      bodyFormData1.append("FbrnId", "");
      bodyFormData1.append("Type", "Univ.Research.Home.About");
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          document.getElementById("postTitle_").innerText =
            response.data[0]["postTitle"];
          document.getElementById("postSubTitle_").innerText =
            response.data[0]["postSubTitle"];
        })
        .catch(function () {
          // console.log("error", response);
        });
    },
    getApis() {
      var self = this;
      //يستقبل رقم الفرع
      var ID = this.$route.params.id;

      var bodyFormData3 = new FormData();
      bodyFormData3.append("Lang", localStorage.getItem("lang"));
      bodyFormData3.append("Id", ID);

      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/branches/GetBrnWebSites",
        data: bodyFormData3,
      }).then(function (response) {
        var BrnData;
        BrnData = response.data;
        if (BrnData.length != 0) {
          document.getElementById("postTitleId").innerText =
            response.data[0]["brnTitle"];
          document
            .getElementById("ImagesFID")
            .setAttribute(
              "src",
              "https://api2.yuniv.net:444/Images/branches/" +
                response.data[0]["brnImage"]
            );
        }

        var bodyFormData = new FormData();

        bodyFormData.append("check", "getPost");
        bodyFormData.append("Lang", localStorage.getItem("lang"));
        bodyFormData.append("PostId", "");
        bodyFormData.append("FbrnId", ID);
        bodyFormData.append("Type", "Univ.Research.Home.About");
        bodyFormData.append("ParentId", "NTROOT0");
        bodyFormData.append("PostName", "");
        bodyFormData.append("Pno", "20");
        axios({
          method: "post",
          url: "https://api2.yuniv.net:444/our_team/getPost",
          data: bodyFormData,
        }).then(function (response) {
          self.About = response.data;

          if (self.About.length != 0) {
            self.getAboutById(response.data[0]["postID"]);
          }
          var bodyFormData = new FormData();
          bodyFormData.append("check", "getPost");
          bodyFormData.append("Lang", localStorage.getItem("lang"));
          bodyFormData.append("FbrnId", ID);
          bodyFormData.append("Type", "Univ.Research.Home.Message");
          bodyFormData.append("ParentId", "NTROOT0");
          bodyFormData.append("PostName", "");
          bodyFormData.append("Pno", "1");
          axios({
            method: "post",
            url: "https://api2.yuniv.net:444/our_team/getPost",
            data: bodyFormData,
          })
            .then(function (response) {
              var array;
              array = response.data;

              if (array.length != 0) {
                self.showMessage = true;
                self.ImageMess = response.data[0]["postImage"];
                self.TitleMess = response.data[0]["postTitle"];
                self.SubMess = response.data[0]["postSubTitle"];
              } else self.showMessage = false;

              var bodyFormData = new FormData();
              bodyFormData.append("check", "getPost");
              bodyFormData.append("Lang", localStorage.getItem("lang"));
              bodyFormData.append("FbrnId", ID);
              bodyFormData.append("Type", "Univ.Research.issue");
              bodyFormData.append("ParentId", "NTROOT0");
              bodyFormData.append("PostName", "");
              bodyFormData.append("Pno", "-1");
              axios({
                method: "post",
                url: "https://api2.yuniv.net:444/our_team/getPost",
                data: bodyFormData,
              })
                .then(function (response) {
                  self.Events = response.data;
                  if (self.Events.length != 0) {
                    self.showActivity = true;
                  } else {
                    self.showActivity = false;
                  }

                  var bodyFormData = new FormData();
                  bodyFormData.append("check", "getPostDetails");
                  bodyFormData.append("Lang", localStorage.getItem("lang"));
                  bodyFormData.append("FbrnId", ID);
                  bodyFormData.append("Type", "Univ.Research.Home.Services");
                  bodyFormData.append("ParentId", "NTROOT0");
                  bodyFormData.append("PostName", "");
                  bodyFormData.append("Pno", "-1");
                  axios({
                    method: "post",
                    url: "https://api2.yuniv.net:444/our_team/getPost",
                    data: bodyFormData,
                  })
                    .then(function (response) {
                      self.Services = response.data;
                      if (self.Services.length != 0) {
                        self.showServices = true;
                      } else {
                        self.showServices = false;
                      }

                      var bodyFormData = new FormData();
                      bodyFormData.append("check", "getPostDetails");
                      bodyFormData.append("Lang", localStorage.getItem("lang"));
                      bodyFormData.append("FbrnId", ID);
                      bodyFormData.append("Type", "Univ.Research.Home.Videos");
                      bodyFormData.append("ParentId", "NTROOT0");
                      bodyFormData.append("PostName", "");
                      bodyFormData.append("Pno", "-1");
                      axios({
                        method: "post",
                        url: "https://api2.yuniv.net:444/our_team/getPost",
                        data: bodyFormData,
                      })
                        .then(function (response) {
                          self.Videos = response.data;
                          if (self.Videos.length != 0) {
                            self.showVideos = true;
                          } else {
                            self.showVideos = false;
                          }
                          document
                            .getElementById("VueMainJsNewTouch")
                            ?.remove();
                          let recaptchaScript = document.createElement(
                            "script"
                          );
                          recaptchaScript.setAttribute("src", "/js/main.js");
                          recaptchaScript.id = "VueMainJsNewTouch";
                          document.head.appendChild(recaptchaScript);
                          setTimeout(() => {
                            self.show = "false";
                            document
                              .querySelectorAll("div.loader")
                              .forEach(function (element) {
                                element.setAttribute("hidden", true);
                              });
                          }, 100);
                        })
                        .catch(function () {
                          // console.log("error", response);
                        });
                    })
                    .catch(function () {
                      // console.log("error", response);
                    });
                })
                .catch(function () {
                  // console.log("error", response);
                });
            })
            .catch(function () {
              // console.log("error", response);
            });
        });
      });
    },
    showVideo(id) {
      var bodyFormData3 = new FormData();
      bodyFormData3.append("check", "getPostById");
      bodyFormData3.append("PostId", id);
      bodyFormData3.append("Lang", localStorage.getItem("lang"));
      bodyFormData3.append("FbrnId", "");
      bodyFormData3.append("Type", "Univ.Research.Home.Videos");
      bodyFormData3.append("ParentId", "NTROOT0");
      bodyFormData3.append("PostName", "");
      bodyFormData3.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData3,
      }).then(function (response) {
        document
          .getElementById("postURL")
          .setAttribute("src", response.data[0]["url"]);
      });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        if (response.data[0]["LangId"] == "Ar") {
          self.translate = self.ArTranslat;
          document.getElementById("langfilewebsites")?.remove();
        } else {
          self.translate = self.EnTranslat;
          let link1 = document.createElement("link");
          link1.setAttribute("rel", "stylesheet");
          link1.id = "langfilewebsites";
          link1.setAttribute("href", "../../style-ltr.css");
          document.head.appendChild(link1);
        }
        self.Magazine = self.translate[0]["Home"]["Magazine"];
        self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
        self.Detials = self.translate[0]["Home"]["Detials"];
        self.DownloadFile = self.translate[0]["Home"]["DownloadFile"];
        self.Service = self.translate[0]["HeaderAndFooter"]["Service"];
        self.LibraryVideos = self.translate[0]["Home"]["LibraryVideos"];
        self.BtnMore1 = self.translate[0]["Home"]["BtnMore"];
        self.watch = self.translate[0]["Home"]["watch"];
        self.getApis();
      });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        document.getElementById("langfilewebsites")?.remove();
      } else {
        self.translate = self.EnTranslat;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../style-ltr.css");
        document.head.appendChild(link1);
      }
      self.Magazine = self.translate[0]["Home"]["Magazine"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.Detials = self.translate[0]["Home"]["Detials"];
      self.DownloadFile = self.translate[0]["Home"]["DownloadFile"];
      self.Service = self.translate[0]["HeaderAndFooter"]["Service"];
      self.LibraryVideos = self.translate[0]["Home"]["LibraryVideos"];
      self.BtnMore1 = self.translate[0]["Home"]["BtnMore"];
      self.watch = self.translate[0]["Home"]["watch"];
      self.getApis();
    }
  },
};
</script>

<style scoped>
.newsletter-area {
  background: #0c3ef7 none repeat scroll 100% 0;
  color: #ffffff;
  position: relative;
}

.newsletter-form {
  overflow: hidden;
  padding: 7px 0;
}

.newsletter-area:before {
  height: 65px;
  right: 0;
  left: auto;
  top: -13px;
  width: 27%;
}

.newsletter-area:after {
  border-width: 32.5px 27px;
  right: 27%;
  left: auto;
  top: -13px;
}

.newsletter-content {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.ticker-left {
  display: inline-block;
  font-weight: bold;
  min-width: 50%;
  margin-top: 0px;
  margin-bottom: -1rem !important;
}

.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 236px;
  height: 234px;
}

.c-text a {
  cursor: pointer;
}
.ImagesFID {
  width: 100% !important;
}
.contact-text p {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 10px;
}
.contact-text p span a {
  color: #fff;
}
.single-item-text {
  padding: 15px;
  height: auto;
}
.course-details-content {
  margin: 0;
}
.single-item-text h5 {
  font-weight: bold;
}
.single-item-text p {
  margin-top: 10px;
  text-align: justify;
  line-height: 30px;
}
.single-course-details {
  padding: 10px 0 !important;
  margin-bottom: 0;
  box-shadow: -1px 8px 15px 5px #ccc;
}
.about-container h3 {
  color: #000;
}
.about-container p {
  color: #fff;
  line-height: 30px;
}
.header-logo-menu.stick.semi-transparent,
.gallery-img,
.gallery-img .hover-effect,
.single-teacher-image > a:after {
  background: rgb(52 152 219 / 63%) none repeat scroll 0 0;
}
#gelery {
  padding: 10px;
}
#groupImages {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 30px;
}
.btn-warning {
  text-align: center;
  margin-top: 30px;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 750px) {
  .text {
    font-size: 11px !important;
  }
}
.modal-body {
  padding: 0;
}
.modal-header {
  border-bottom: 0;
}
.modal-header .close {
  color: #fff;
  opacity: 1;
  margin: -1rem -1rem -1rem auto;
}
.modal-content {
  background-color: transparent;
  border: none;
}
</style>
